<template>
  <div class="intro" id="product">
    <div class="ui grid sixteen wide column row">

      <div class="slide-content">
        <vueper-slides vueperslides--animated slide-image-inside :infinite="true"
          :duration=3000
          :autoplay= "true"
          fixed-height="100vh"
          width="100%"
          :slide-ratio="1 / 2"
          fixed-width="100%"
          class="no-shadow"
          :bullets="true"
          :arrows="false"
          :visible-slides="1"
          :dragging-distance="70"
          >

          <vueper-slide>
            <template #content>
              <div class="vueperslide__content">
                <div class="v2home">
                  <div class="ui grid container-1 v2">
                    <div class="v2-content advisory newservice">
                      <h1><span>|</span> Advisory</h1>
                      <h2>New Service Fees</h2>
                      <h4>Effective <b>April 20, 2022</b>, we will be implementing new fees for these channels:</h4>
                      <div class="table-body">
                        <table class="ui celled table unstackable">
                          <thead>
                            <tr>
                              <th>BANK TRANSFER CHANNEL</th>
                              <th>SERVICE FEES</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><b>InstaPay</b>
                              </td>
                              <td>Php 12.00</td>
                            </tr>
                            <tr>
                              <td>
                                <b>PESONet</b>
                              </td>
                              <td>Php 5.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Service fees for all other services shall remain the same.</h5>
                      <router-link class="link" @click.native="removehash" :to="{ path: '/faq/feesandcharges', hash: '#feesandcharges'}" replace>
                        Learn more about our complete line up of services and fees.
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </vueper-slide>

          <vueper-slide>
            <template #content>
              <div class="vueperslide__content">
                <div class="v2home">
                  <div class="ui grid container-1 v2">
                    <div class="v2-content advisory">
                      <h1><span>|</span> Advisory</h1>
                      <h2>New Cash In Service Fees</h2>
                      <h4>Effective <b>December 1, 2022</b>, we are implementing new service fees for Cash In transactions via these channels:</h4>
                      <div class="table-body">
                        <table class="ui celled table unstackable">
                          <thead>
                            <tr>
                              <th>CASH IN CHANNELS</th>
                              <th>SERVICE FEES</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><b>Cash In via Bank/EMI Apps of Instapay Partners<br></b>
                                <a class="link" href="https://assets.prod.tayocash.com/forms/instapay_partners.pdf" download target="_blank">Updated List of Instapay Participants</a>
                              </td>
                              <td>Fees are as charged by Instapay Partners</td>
                            </tr>
                            <tr>
                              <td><b>Cash In via Over-the-Counter Partner Outlets <br></b>
                                <a class="link" href="https://assets.prod.tayocash.com/forms/partner_outlets.pdf" download target="_blank">Updated List of OTC Partner Outlets</a>
                              </td>
                              <td>0.90% for Cash in below Php 2,000, <label class="red">Free</label> for Cash in amounting to Php 2,000 and above </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Cash In via Online Banking<br>
                                  (RCBC and BPI via BUX)
                                </b>
                              </td>
                              <td>Php 15.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Service fees for all other Cash In channels and other services shall remain the same.</h5>
                      <router-link class="link" @click.native="removehash" :to="{ path: '/faq/feesandcharges', hash: '#feesandcharges'}" replace>
                        Learn more about our complete line up of services and fees.
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </vueper-slide>

          <vueper-slide>
            <template #content>
              <div class="vueperslide__content">
                <div class="intro-content">
                 <h3>HELLO.</h3>
                 <h1>WE’RE TAYOCASH.</h1>

                 <p class="first">Your newest mobile wallet welcomes you to a community
                   where financial technology is made closer to everyone.</p>

                 <p class="second">Join us and experience safe and convenient
                 digital transactions on the go.</p>

                 <h4>DOWNLOAD NOW ON:</h4>
                 <div class="download">
                   <a href="https://apps.apple.com/ph/app/tayocash/id1547566625" class="apple" target="_blank">
                     <div class="apple-logo"></div>
                     <div class="text">
                       Download on the
                       <span>App Store</span>
                     </div>
                   </a>
                   <a href="https://play.google.com/store/apps/details?id=com.tayocash" class="android" target="_blank">
                     <div class="android-logo"></div>
                     <div class="text">
                       GET IT ON
                       <span>Google Play</span>
                     </div>
                   </a>
                 </div>
                </div>
                <div class="image-div">
                  <img class="people" src="@/assets/people.png" alt="Tayocash">
                </div>
              </div>
            </template>
          </vueper-slide>

        </vueper-slides>
      </div>
    </div>


      </div>
    </template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'content1',
  data () {
    return {
      firstSlide: true,
      lastSlide: false
    }
  },
  components: {
    VueperSlides, VueperSlide
  },
  methods: {
    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    },
  }
}
</script>

<style scoped lang='scss'>
</style>
